
import { useTransactionTypeMappingMaintenanceTracked } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TransactionTypeMappingMaintenance from "./TransactionTypeMappingMaintenance";
import { TransactionTypeMappingMaintenanceFormPanel } from "./TransactionTypeMappingMaintenanceFormPanel";

export const TransactionTypeMappingMasterView: React.FC = () => {
    const [transactionTypeMappingState] = useTransactionTypeMappingMaintenanceTracked();
    const { isAdd, isEditable } = transactionTypeMappingState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <TransactionTypeMappingMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<TransactionTypeMappingMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}