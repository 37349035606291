
import { EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY, TransactionTypeMappingEntity } from "domain/entity/TransactionTypeMapping/TransactionTypeMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TransactionTypeMappingDropdownOptions {
    docTypeDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: {[key: string]: DropdownProps[]},
    adjTypeDropdownOptions: DropdownProps[],
    adjustmentSubTypeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: TransactionTypeMappingEntity,
}

export interface TransactionTypeMappingMaintenanceModel {
    isLoading: boolean,
    tableData: TransactionTypeMappingEntity[],
    currentSelectedRow: TransactionTypeMappingEntity,
    selectedRows: TransactionTypeMappingEntity[],
    dynamicOptions: TransactionTypeMappingDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_TRANSACTION_TYPE_MAPPINGANY_MODEL: TransactionTypeMappingMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        docTypeDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        adjTypeDropdownOptions: [],
        adjustmentSubTypeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY,
    },
    isBackMaster: false,
}