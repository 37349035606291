
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY, TransactionTypeMappingEntity } from "domain/entity/TransactionTypeMapping/TransactionTypeMappingEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { TransactionTypeMappingRepository } from "domain/repository/TransactionTypeMapping/TransactionTypeMappingRepo";
import _ from "lodash";
import { AdjustmentSubTypeDroOpts } from "presentation/constant/DropDownOptions/Charge/AdjustmentSubTypeDroOpts";
import { AdjustmentTypeDroOpts } from "presentation/constant/DropDownOptions/Charge/AdjustmentTypeDroOpts";
import { DocumentTypeDroOpts } from "presentation/constant/DropDownOptions/Document/DocumentTypeDroOpts";
import { createTransactionTypeMappingMaintenanceValidationSchema } from "presentation/constant/TransactionTypeMapping/TransactionTypeMappingMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TransactionTypeMappingMaintenanceModel } from "presentation/model/TransactionTypeMapping/TransactionTypeMappingMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";


interface TransactionTypeMappingMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TransactionTypeMappingMaintenanceModel>> | ((value: SetStateAction<TransactionTypeMappingMaintenanceModel>) => void),
    ],
    repo: TransactionTypeMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
}

export const TransactionTypeMappingMaintenanceVM = ({dispatch,repo,chargeTypeRepo}:TransactionTypeMappingMaintenanceVMProps) => {
    const [transactionTypeMappingMainDispatch] = dispatch;
        
    const loadDropdownOption = async () => {

        transactionTypeMappingMainDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                docTypeDropdownOptions: DocumentTypeDroOpts().getDocumentTypeModel(),
            }
        }))


        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOption:DropdownProps[] = [];
                let subChargeTypeDropdownOption:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                transactionTypeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        
        // let companyCodeDropdownOption = AdjustmentTypeDroOpts().getAdjustmentTypeModel()?.map((item) => ({
        //     dropdownLabel: item.dropdownLabel,
        //     tagLabel: item.tagLabel,
        //     value: item.value,
        // })) ?? []
    

        transactionTypeMappingMainDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                adjTypeDropdownOptions: AdjustmentTypeDroOpts().getAdjustmentTypeModel(),
            }
        }))
        
        transactionTypeMappingMainDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                adjustmentSubTypeDropdownOptions: AdjustmentSubTypeDroOpts().getAdjustmentSubTypeModel(),
            }
        }))

    }

    const onSearch = async () => {
        const entities = await repo.getEntities();

        const newData = entities.map(entity => ({  
            ...entity,
            adjType: AdjustmentTypeDroOpts().getToValueByKey(entity.adjType as string)
        }));

        transactionTypeMappingMainDispatch(prevState =>({
            ...prevState,
            tableData: newData,
            selectedRows: [],
            currentSelectedRow: EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (allRows:TransactionTypeMappingEntity[], selecedRows:TransactionTypeMappingEntity[]) => {
        transactionTypeMappingMainDispatch(prevState => {
             
            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        transactionTypeMappingMainDispatch(prevState => {
             
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: TransactionTypeMappingEntity)=>{
        transactionTypeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = ()=>{
        transactionTypeMappingMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        transactionTypeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY,
                    allFormState:{}
                },
                isBackMaster: true,
            }
        })
    }

    const onSaveClicked = () => {
        transactionTypeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: TransactionTypeMappingEntity,isAdd: boolean) => {
        const valResult = isAdd? await Validation(createTransactionTypeMappingMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: {[x: string]: string} = {};  
        if (valResult) {
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' }; 

            transactionTypeMappingMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }                        
                }
            });

            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        
        currentEntity = {
            ...currentEntity,
            adjType: AdjustmentTypeDroOpts().getToKeyByValue(currentEntity.adjType as string)
        }

        if(isAdd){
            return await repo.createEntity(currentEntity);
        }else{
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        transactionTypeMappingMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity : {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if(fieldKey === 'transactionType'){
            val = val.toUpperCase();
        }

        transactionTypeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        transactionTypeMappingMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }


    return {
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
    }
}