import { TransactionTypeMappingMaintenanceConstant } from "presentation/constant/TransactionTypeMapping/TransactionTypeMappingMaintenanceConstant";
import { useTransactionTypeMappingMaintenanceVM } from "presentation/hook/TransactionTypeMapping/useTransactionTypeMappingMaintenanceVM";
import { useTransactionTypeMappingMaintenanceTracked } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useEffect, useMemo, useState } from "react";
import { FieldType, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TransactionTypeMappingEditPanelTitleBar from "./TransactionTypeMappingEditPanelTitleBar";

export const TransactionTypeMappingMaintenanceFormPanel = () => {
    const [transactionTypeMappingState] = useTransactionTypeMappingMaintenanceTracked();
    const transactionTypeMappingVM = useTransactionTypeMappingMaintenanceVM();
    const {currentSelectedRow, masterState,dynamicOptions} = transactionTypeMappingState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = TransactionTypeMappingMaintenanceConstant;
    const [isLoading, setIsLoading] = useState(false);
    const {isBackMaster} = transactionTypeMappingState;
    
    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    transactionTypeMappingVM.onSearch(),
                    transactionTypeMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => {  
            setIsLoading(false);
        }).catch(error => {            
            setIsLoading(false);
        });
    }, [isBackMaster,transactionTypeMappingVM])

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEntity.chargeType?dynamicOptions.subChargeTypeDropdownOptions[currentEntity.chargeType]:[];
        return subChgTypeOptions;
    }, [currentEntity.chargeType, dynamicOptions.subChargeTypeDropdownOptions])


    const memoDocType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentEntity?.docType || ''}
                fieldValue={currentEntity?.docType || ''}
                fieldLabel={SCREEN_CONSTANT.DOC_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'docType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.docTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, currentEntity?.docType, SCREEN_CONSTANT.DOC_TYPE, isSaveClicked, dynamicOptions.docTypeDropdownOptions, transactionTypeMappingVM])

        const memoChargeType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.chargeType || ''}
                    fieldValue={currentEntity?.chargeType || ''}
                    fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'chargeType'}
                    isShowMissingError={true}
                    maxLength={60}
                    options={dynamicOptions.chargeTypeDropdownOptions}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, dynamicOptions.chargeTypeDropdownOptions, transactionTypeMappingVM])
        
        const memoSubChargeType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.subChargeType || ''}
                    fieldValue={currentEntity?.subChargeType || ''}
                    fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'subChargeType'}
                    isShowMissingError={true}
                    maxLength={60}
                    options={memoSubChgTypeOptions}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.subChargeType, SCREEN_CONSTANT.SUB_CHARGE_TYPE, isSaveClicked, memoSubChgTypeOptions, transactionTypeMappingVM])

            const memoAdjType = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
        
                    <NbisCommonField
                        errorMessages={allFormState}
                        isReadOnly={isRead}
                        isShowOptional={false}
                        readOnlyValue={currentEntity?.adjType || ''}
                        fieldValue={currentEntity?.adjType || ''}
                        fieldLabel={SCREEN_CONSTANT.ADJ_TYPE}
                        isSaveClicked={isSaveClicked}
                        fieldType={FieldType.DROPDOWN}
                        fieldKey={'adjType'}
                        isShowMissingError={true}
                        maxLength={10}
                        options={dynamicOptions.adjTypeDropdownOptions}
                        requiredFieldList={[]}
                        onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                            transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
                </div>
                , [allFormState, isRead, currentEntity?.adjType, SCREEN_CONSTANT.ADJ_TYPE, isSaveClicked, dynamicOptions.adjTypeDropdownOptions, transactionTypeMappingVM])

            const memoAdjSubType = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
        
                    <NbisCommonField
                        errorMessages={allFormState}
                        isReadOnly={isRead}
                        isShowOptional={true}
                        readOnlyValue={currentEntity?.adjSubType || ''}
                        fieldValue={currentEntity?.adjSubType || ''}
                        fieldLabel={SCREEN_CONSTANT.ADJ_SUB_TYPE}
                        isSaveClicked={isSaveClicked}
                        fieldType={FieldType.DROPDOWN}
                        fieldKey={'adjSubType'}
                        isShowMissingError={true}
                        maxLength={10}
                        options={dynamicOptions.adjustmentSubTypeDropdownOptions}
                        requiredFieldList={[]}
                        onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                            transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
                </div>
                , [allFormState, isRead, currentEntity?.adjSubType, SCREEN_CONSTANT.ADJ_SUB_TYPE, isSaveClicked,dynamicOptions.adjustmentSubTypeDropdownOptions, transactionTypeMappingVM])
    
            const memoTransType = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
        
                    <NbisCommonField
                        errorMessages={allFormState}
                        isReadOnly={isRead}
                        isShowOptional={false}
                        readOnlyValue={currentEntity?.transactionType || ''}
                        fieldValue={currentEntity?.transactionType || ''}
                        fieldLabel={SCREEN_CONSTANT.TRAS_TYPE}
                        isSaveClicked={isSaveClicked}
                        fieldType={FieldType.TEXT}
                        fieldKey={'transactionType'}
                        isShowMissingError={true}
                        maxLength={30}
                        requiredFieldList={[]}
                        onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                            transactionTypeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
                </div>
                , [allFormState, isRead, currentEntity?.transactionType, SCREEN_CONSTANT.TRAS_TYPE, isSaveClicked, transactionTypeMappingVM])
    
                

        const memoActiveInd = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd==='Y'}
                    disabled={isRead}
                    onChange={(e) => transactionTypeMappingVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, transactionTypeMappingVM])

        
    const memoEditPanelTitleBar = useMemo(() => {
        return <TransactionTypeMappingEditPanelTitleBar/>
    }, [])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{!isAdd ? currentEntity.transactionType : "NEW CODE"}</SidebarTitle>

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                        {memoTransType}{memoDocType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeType}{memoSubChargeType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoAdjType}{memoAdjSubType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
